<template>
  <div>
    <div class="col float-right">
      <q-btn-group outline>
        <c-update-btn 
          name="updateBtn"
          :data="param.planUpdateBtnData"
          :btnEditable="btnEditable"
          :flagCondition="flagCondition"
          @back="back"
        />
        <c-btn 
          v-show="editable&&!deleteDisabled&&isOld" 
          label="LBLREMOVE" 
          icon="remove" 
          @btnClicked="removePlan" />
        <!-- 지난 위험성평가 재검토 -->
        <c-btn 
          v-show="editable&&isOld&&!disabled&&!techCheck" 
          label="LBL0001043"
          icon="autorenew" 
          @btnClicked="reCheckRisk" />
        <c-btn 
          v-show="editable&&!disabled"
          :isSubmit="isComplete"
          :url="completeUrl"
          :param="assessPlan"
          mappingType="PUT"
          label="LBLCOMPLETE" 
          icon="check"
          @beforeAction="completePlan"
          @btnCallback="completePlanCallback" />
      </q-btn-group>
    </div>
    <c-tab
      type="vertical"
      :tabItems="tabItems"
      :height.sync="tabHeight"
      v-model="tab"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
          :key="tab.key"
          :assessPlan.sync="assessPlan"
          :attachInfo.sync="attachInfo"
          :searchFlag.sync="searchFlag"
          :vendorFlag="param.vendorFlag"
          :updateBtnData.sync="param.planUpdateBtnData"
          @loadAssess="loadAssess"
          @saveCallback="saveCallback"
        />
      </template>
    </c-tab>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'riskPlan',
  props: {
    param: {
      type: Object,
      default: () => ({
        ramRiskAssessmentPlanId: '',
        ramStepCd: '',
        vendorFlag: false,
        ramTechniqueCd: '',
        planUpdateBtnData: {
          title: 'LBLPLAN',
          flag: false,
          research: '',
          planResearch: '',
        },
      }),
    },
    height: {
      type: String,
      default: function() {
        return '';
      },
    },
  },
  data() {
    return {
      splitter: 5,
      tab: 'planInfo',
      assessPlan: {
        ramRiskAssessmentPlanId: '',  // 평가 일련번호
        plantCd: '',  // 사업장 코드
        ramTechniqueCd: null,  // 평가기법 코드_HAZOP/4m/JSA/CHECKLIST
        ramAssessTypeCd: null,  // 평가구분_최초/정기/수시
        assessmentName: '',  // 평가명
        assessmentStartDate: '',  // 평가시작일
        assessmentEndDate: '',  // 평가종료일
        assessmentPeriod: [],
        assessmentYear: '',  // 평가년도
        ramStepCd: '',  // 평가 진행단계 코드
        assessmentManageDeptCd: '',  // 평가주관 부서 코드
        assessmentManageUserId: '',  // 평가주관 담당자 ID
        ramMatrixId: null,  // 위험메트릭스 번호
        remark: '',  // 상세내용
        workArea: '',  // 작업지역
        regUserId: '',  // 작성자 ID
        regUserName: '',  // 작성자 ID
        regDtStr: '',
        reviewUserId: '',  // 검토자 ID
        reviewDate: '',  // 검토일
        approvalUserId: '',  // 승인자 ID
        approvalDate: '',  // 승인일
        vendorCd: '',
        sopConstructionId: '',
        chgUserId: '',  // 수정자 ID
        teams: [], // 평가팀 목록
        occasions: [], // 수시 변경/추가 내역 목록
        conferences: [], // 회의 목록
        relatedDocuments: [], // 관련자료 목록
        processes: [], // 대상공정 목록
      },
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'RISK_PLAN',
        taskKey: '',
      },
      searchFlag: {
        value: '',
      },
      editable: true,
      detailUrl: '',
      completeUrl: '',
      deleteUrl: '',
      loadUrl: '',
      recheckSaveUrl: '',
      isComplete: false,
      popupOptions: {
        isApproval: true,
        target: null,
        title: '',
        visible: false,
        param: {},
        closeCallback: null,
      },
    };
  },
  computed: {
    tabHeight() {
      let numHeight = this.height ? Number(this.$_.replace(this.height, 'px', '')) : 600;
      if ((this.editable && !this.disabled) || this.btnEditable) {
        numHeight = numHeight - 30
      }
      return numHeight + 'px';
    },
    tabItems() {
      return [
        // 평가계획
        { key: uid(), name: 'planInfo', icon: 'edit', label: 'LBL0001044', component: () => import(`${'./riskPlanInfo.vue'}`) },
        // 대상공정
        // 대상작업
        { key: uid(), name: 'targetProcess', icon: 'category', label: !this.param.vendorFlag ? 'LBL0001045' : 'LBL0001046', component: () => import(`${'./riskTargetProcess.vue'}`), disabled: (this.param.ramRiskAssessmentPlanId ? false : true) },
        // 회의 및<br/>관련자료
        { key: uid(), name: 'team', icon: 'checklist', label: 'LBL0001047', component: () => import(`${'./riskAnalysisTeam.vue'}`), disabled: (this.param.ramRiskAssessmentPlanId ? false : true) },
      ]
    },
    isOld() {
      return Boolean(this.assessPlan.ramRiskAssessmentPlanId)
    },
    deleteDisabled() {
      return this.assessPlan.ramStepCd === 'RRS0000015'
    },
    disabled() {
      return this.assessPlan.ramStepCd !== 'RRS0000001' || !this.assessPlan.ramRiskAssessmentPlanId
    },
    btnEditable() {
      return this.editable && this.assessPlan.ramStepCd === 'RRS0000010' && Boolean(this.assessPlan.ramRiskAssessmentPlanId)
    },
    flagCondition() {
      return this.assessPlan.ramStepCd === 'RRS0000001'
    },
    techCheck() {
      return false;
      // HAZOP, CHARM만 기능 비활성화
      // return this.assessPlan.ramTechniqueCd === 'RT00000001'
      //   || this.assessPlan.ramTechniqueCd === 'RT00000030'
    },
    riskValid() {
      let _riskValid = {};
      if (this.param.ramTechniqueCd === 'RT00000001') {
        // HAZOP
        _riskValid = {
          list: 'drawNodes',
          cols: ['nodeNo'],
          nolist: this.$comm.getLangMessage('MSG0000371'), // 대상공정[{0}]에 도면&노드가 입력되지 않았습니다.
          riskTarget: this.$comm.getLangLabel('LBL0001048'), // 도면&노드
        }
      } else if (this.param.ramTechniqueCd === 'RT00000005') {
        // K-PSR
        _riskValid = {
          list: 'drawNodes',
          cols: ['nodeNo'],
          nolist: this.$comm.getLangMessage('MSG0000371'), // 대상공정[{0}]에 도면&노드가 입력되지 않았습니다.
          riskTarget: this.$comm.getLangLabel('LBL0001048'), // 도면&노드
        }
      } else if (this.param.ramTechniqueCd === 'RT00000010') {
        // Check-list
        _riskValid = {
          list: 'targets',
          cols: ['checkItemName'],
          nolist: this.$comm.getLangMessage('MSG0000372'), // 대상공정[{0}]에 평가대상이 입력되지 않았습니다.
          riskTarget: this.$comm.getLangLabel('LBL0001028'), // 평가대상
        }
      } else if (this.param.ramTechniqueCd === 'RT00000015') {
        // JRA
        _riskValid = {
          list: 'sops',
          cols: ['jobName', 'jobStepName', 'riskHazardName'],
          nolist: this.$comm.getLangMessage('MSG0000373'), // 대상공정[{0}]에 SOP가 입력되지 않았습니다.
          riskTarget: this.$comm.getLangLabel('LBL0001049'), // SOP
        }
      } else if (this.param.ramTechniqueCd === 'RT00000020') {
        // KRAS
        _riskValid = {
          list: 'scenarios',
          cols: ['riskHazardName'],
          nolist: this.$comm.getLangMessage('MSG0000373'), // 대상공정[{0}]에 SOP가 입력되지 않았습니다.
          riskTarget: this.$comm.getLangLabel('LBL0001049'), // SOP
        }
      } else if (this.param.ramTechniqueCd === 'RT00000025') {
        // 4M
        _riskValid = {
          list: 'fmScenarios',
          cols: ['riskHazardName', 'ram4mRiskHazardClassName'],
          nolist: this.$comm.getLangMessage('MSG0000374'), // 대상공정[{0}]에 유해위험요인이 입력되지 않았습니다.
          riskTarget: this.$comm.getLangLabel('LBL0001050'), // 유해위험요인
        }
      } else if (this.param.ramTechniqueCd === 'RT00000030') {
        // CHARM
        _riskValid = {
          list: 'charmResultScenarios',
          cols: [],
          nolist: 'MSG0000375', // 대상공정[{0}]에 화학자재 파악이 되지 않았습니다.
          riskTarget: this.$comm.getLangLabel('LBL0000356'), // 화학자재 
        }
      } else if (this.param.ramTechniqueCd === 'RT00000035') {
        // 3단계 판단법
        _riskValid = {
          list: 'thirdScenarios',
          cols: ['riskHazardQuestionName'],
          nolist: this.$comm.getLangMessage('MSG0000376'), // 대상공정[{0}]에 평가대상 파악이 되지 않았습니다.
          riskTarget: this.$comm.getLangLabel('LBL0001028'), // 평가대상
        }
      }
      return _riskValid;
    },
  },
  watch: {
    'param.planUpdateBtnData.planResearch'() {
      this.getDetail();
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.ram.assessPlan.get.url;
      this.completeUrl = transactionConfig.ram.assessPlan.complete.url
      this.deleteUrl = transactionConfig.ram.assessPlan.delete.url
      this.loadUrl = transactionConfig.ram.assessPlan.load.url
      this.recheckSaveUrl = transactionConfig.ram.assessPlan.recheck.url
      // code setting
      // list setting
      this.getDetail();
    },
    getDetail() {
      if (this.param.ramRiskAssessmentPlanId) {
        this.$http.url = this.$format(this.detailUrl, this.param.ramRiskAssessmentPlanId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.$_.extend(this.assessPlan, _result.data);
          this.assessPlan.assessmentPeriod = this.assessPlan.assessmentStartDate && this.assessPlan.assessmentEndDate ?
            [this.assessPlan.assessmentStartDate, this.assessPlan.assessmentEndDate] : []
          this.$set(this.attachInfo, 'taskKey', this.assessPlan.ramRiskAssessmentPlanId)
          this.searchFlag.value = uid();
        },);
      } else {
        this.assessPlan.assessmentYear = this.$comm.getThisYear()
        this.assessPlan.assessmentManageUserId = this.$store.getters.user.userId
        this.assessPlan.assessmentManageDeptCd = this.$store.getters.user.deptCd
        this.assessPlan.regUserId = this.$store.getters.user.userId
        this.assessPlan.regUserName = this.$store.getters.user.userName
        this.assessPlan.regDtStr = this.$comm.getTodayDateTime();
        this.assessPlan.ramTechniqueCd = this.param.ramTechniqueCd
      }
    },
    reCheckRisk() {
      /**
       * 재검토
       * 
       *  - 지난 위험성평가에서 진행한 유해위험요인에 대해서 재검토 진행
       *  - 위험성평가 한 개 이상 선택
       *  - 대상 공정 포함 아래 정보 summary되어 추가
       */
      this.popupOptions.title = "LBL0001051"; // 위험성평가 재검토 검색
      this.popupOptions.param = {
        ramRiskAssessmentPlanId: this.assessPlan.ramRiskAssessmentPlanId,
        ramTechniqueCd: this.assessPlan.ramTechniqueCd,
        vendorFlag: this.param.vendorFlag,
        actionFlag: false,
      };
      this.popupOptions.target = () => import(`${"@/pages/ram/reCheckAssessmentPop.vue"}`);
      this.popupOptions.width = "90%";
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeRecheckPopup;
    },
    closeRecheckPopup(data, summaryData) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        let filteredArr = data.reduce((acc, current) => {
          const x = acc.find(item => item.ramRiskAssessmentPlanId === current.ramRiskAssessmentPlanId && item.processCd === current.processCd);
          if (!x) {
            return acc.concat([current]);
          } else {
            return acc;
          }
        }, []);
        let _recheckData = [];
        this.$_.forEach(summaryData, _item1 => {
          this.$_.forEach(filteredArr, _item2 => {
            if (_item1.ramRiskAssessmentPlanId === _item2.ramRiskAssessmentPlanId
            && _item1.processCd === _item2.processCd) {
              _recheckData.push(_item1);
            }
          })
        })

        let _ramRiskAssessmentItems = [];
        this.$_.forEach(filteredArr, item => {
          _ramRiskAssessmentItems.push(
            {
              ramRiskAssessmentPlanId: item.ramRiskAssessmentPlanId,
              processCd: item.processCd,
            }
          )
        })

        this.$http.url = this.recheckSaveUrl;
        this.$http.type = 'POST';
        this.$http.param = {
          ramRiskAssessmentPlanId: this.param.ramRiskAssessmentPlanId,
          ramTechniqueCd: this.param.ramTechniqueCd,
          regUserId: this.$store.getters.user.userId,
          recheckData: _recheckData,
          ramRiskAssessmentPlanIds: this.$_.map(filteredArr, 'ramRiskAssessmentPlanId'),
          ramRiskAssessmentItems: _ramRiskAssessmentItems,
        };
        // console.log('_recheckData',_recheckData)
        this.$http.request(() => {
          window.getApp.$emit('APP_REQUEST_SUCCESS');
          this.getDetail();
        },);
      }
    },
    loadAssess(ramRiskAssessmentPlanId) {
      this.$http.url = this.loadUrl;
      this.$http.type = 'POST';
      this.$http.param = {
        ramRiskAssessmentPlanId: ramRiskAssessmentPlanId,
        ramTechniqueCd: this.param.ramTechniqueCd
      };
      this.$http.request((_result) => {
        window.getApp.$emit('APP_REQUEST_SUCCESS');
        this.saveCallback(_result.data)
      },);
    },
    saveCallback(data) {
      this.$set(this.param, 'ramRiskAssessmentPlanId', data)
      this.$emit('emitStep', {
        name: 'keySetting',
        param: data
      })
      this.$set(this.tabItems[1], 'disabled', false)
      this.$set(this.tabItems[2], 'disabled', false)
      this.getDetail();
    },
    completePlan() {
      // 평가팀이 구성되었는지?
      // 회의를 진행했는지?
      // 대상공정을 지정했는지?
      // 대상공정별 도면/노드가 하나 이상 지정되었는지?
      let isProgress = true;
      if (!this.assessPlan.teams || this.assessPlan.teams.length === 0) {
        isProgress = false
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSG0000377', // 평가팀이 구성되지 않았습니다.
          type: 'warning', // success / info / warning / error
        });
      } else if (!this.assessPlan.processes || this.assessPlan.processes.length === 0) {
        isProgress = false
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSG0000378', // 지정된 대상공정이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$_.forEach(this.assessPlan.processes, process => {
          if (!process.assessUserId) {
            isProgress = false
            window.getApp.$emit('ALERT', {
              title: 'LBLGUIDE', // 안내
              // 대상공정[' + process.processName + ']에 상세정보가 입력되지 않았습니다.
              message: this.$comm.getLangMessage('MSG0000379', {s1: process.processName}), 
              type: 'warning', // success / info / warning / error
            });
            return false;
          } else if (!process[this.riskValid.list] || process[this.riskValid.list].length === 0) {
            isProgress = false
            window.getApp.$emit('ALERT', {
              title: 'LBLGUIDE', // 안내
              // this.$format(this.riskValid.nolist, process.processName)
              message: this.$format(this.riskValid.nolist, process.processName),
              type: 'warning', // success / info / warning / error
            });
            return false;
          } else {
            this.$_.forEach(process[this.riskValid.list], risk => {
              if (risk.editFlag === 'C' || risk.editFlag === 'U') {
                console.log(risk)
                isProgress = false
                window.getApp.$emit('ALERT', {
                  title: 'LBLGUIDE', // 안내
                  // 아직 입력 중인 `$this.riskValid.riskTarget}` 정보가 있습니다.\n입력을 마무리 한 후 저장하신 다음 이용하시기 바랍니다.
                  message: this.$comm.getLangMessage('MSG0000380', {s1: this.riskValid.riskTarget}), 
                  type: 'warning', // success / info / warning / error
                });
                return false;
              } else if (this.riskValid.cols && this.riskValid.cols.length > 0) {
                this.$_.forEach(this.riskValid.cols, col => {
                  if (!risk[col]) {
                    isProgress = false
                    window.getApp.$emit('ALERT', {
                      title: 'LBLGUIDE', // 안내
                      // `'${this.riskValid.riskTarget}' 정보가 입력되지 않은 행이 있습니다.`,
                      message: this.$comm.getLangMessage('MSG0000381', {s1: this.riskValid.riskTarget}), 
                      type: 'warning', // success / info / warning / error
                    });
                    return false;
                  }
                })
              }
              if (!isProgress) {
                return false;
              }
            })
            if (!isProgress) {
              return false;
            }
          }
        });

        if (isProgress) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGCOMPLETE', // 완료하시겠습니까?, // 완료하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.assessPlan.chgUserId = this.$store.getters.user.userId
              this.assessPlan.ramStepCd = 'RRS0000010'

              this.isComplete = !this.isComplete
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        }
      }
    },
    completePlanCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      // 계획 상세 조회
      this.getDetail();
      // stepper 이동
      this.$emit('emitStep', {
        name: 'stepBystep',
        param: this.assessPlan.ramStepCd
      })
    },
    removePlan() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: 'MSGREMOVE', // 삭제하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.assessPlan.ramRiskAssessmentPlanId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('emitStep', {
              name: 'closePopup'
            })
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    /**
     * 재조회
     * 목적 : 돌아가기전 데이터를 저장 했을 수도 있지만 
     *        입력만 하고 돌아가는 경우를 대비하기 위함
     */
    back() {
      this.getDetail();
    },
  }
};
</script>
